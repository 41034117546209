export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no"},{"charset":"utf-8"}],"link":[{"rel":"stylesheet","href":"/glider.min.css"},{"rel":"stylesheet","href":"https://unpkg.com/@mdi/font@6.5.95/css/materialdesignicons.min.css"}],"style":[],"script":[{"src":"/flexible.js"},{"src":"/glider.js"},{"src":"/js/lazysizes.min.js","async":true},{"src":"//static.klaviyo.com/onsite/js/klaviyo.js?company_id=TuNGGD","async":true},{"src":"/facebookPixel.js","async":true},{"src":"//dynamic.criteo.com/js/ld/ld.js?a=104558","async":true},{"src":"/tiktok.js","async":true},{"src":"/klaviyo.js","async":true},{"src":"/fbInit.js"},{"src":"/bing.js","async":true},{"src":"//connect.facebook.net/en_US/sdk.js","async":true},{"src":"//www.googletagmanager.com/gtag/js?id=G-T50J7BFXVB","async":true},{"src":"//www.googletagmanager.com/ns.html?id=GTM-KBQ2RWCF"},{"src":"//www.googletagmanager.com/gtag/js?id=AW-10865757270","async":true},{"src":"/hotjar.js","async":true},{"src":"/google.js"}],"noscript":[{"src":"/fbscript.js"}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null