import { default as _4040pELUbE7osMeta } from "E:/code/website/pages/404.vue?macro=true";
import { default as authenticationAvYtmjPYsBMeta } from "E:/code/website/pages/authentication.vue?macro=true";
import { default as indexgFZH48mx4RMeta } from "E:/code/website/pages/cart/index.vue?macro=true";
import { default as _91_91id_93_93ZYfEmCKDrvMeta } from "E:/code/website/pages/checkout/[[id]].vue?macro=true";
import { default as _91_91words_93_93aXeWLIl2axMeta } from "E:/code/website/pages/collections/[uc]/[[words]].vue?macro=true";
import { default as _91_91specs_93_93ammNUqfy9xMeta } from "E:/code/website/pages/collections/[uc]/products/[uc2]/[[specs]].vue?macro=true";
import { default as indexcdPX4bD39IMeta } from "E:/code/website/pages/comment/index.vue?macro=true";
import { default as email_45verify94L10sbeBcMeta } from "E:/code/website/pages/email-verify.vue?macro=true";
import { default as indexLTxt6vO8ATMeta } from "E:/code/website/pages/engagementRing/index.vue?macro=true";
import { default as indexj4SmXmGDxDMeta } from "E:/code/website/pages/error/index.vue?macro=true";
import { default as _91pay_93V18eCGT3wJMeta } from "E:/code/website/pages/giftCard/[pay].vue?macro=true";
import { default as indexSVigrM5bguMeta } from "E:/code/website/pages/giftCard/index.vue?macro=true";
import { default as index2D5rHdUk5uMeta } from "E:/code/website/pages/goodsDetail/index.vue?macro=true";
import { default as indexD8ckpUX4Y4Meta } from "E:/code/website/pages/index.vue?macro=true";
import { default as _91id_93KumrOLTUy3Meta } from "E:/code/website/pages/login-agent/[id].vue?macro=true";
import { default as index3S5TONSZllMeta } from "E:/code/website/pages/orderDetail/[id]/index.vue?macro=true";
import { default as index36VOvFCm9RMeta } from "E:/code/website/pages/orderDetail/replenish_data/[id]/index.vue?macro=true";
import { default as indexWZ1JJD0NhTMeta } from "E:/code/website/pages/pages/[uc]/index.vue?macro=true";
import { default as about_45msblueBnnYiHkS84Meta } from "E:/code/website/pages/pages/about-msblue.vue?macro=true";
import { default as breast_45cancer_45awareness_45jewelryEnsxKoJwfCMeta } from "E:/code/website/pages/pages/breast-cancer-awareness-jewelry.vue?macro=true";
import { default as contact_45usHa51SdKaeiMeta } from "E:/code/website/pages/pages/contact-us.vue?macro=true";
import { default as endless_45blue_45sapphire_45collection7GWGkvxGqAMeta } from "E:/code/website/pages/pages/endless-blue-sapphire-collection.vue?macro=true";
import { default as _91uc_93Rns1xvgorWMeta } from "E:/code/website/pages/pages/faq/[uc].vue?macro=true";
import { default as indexGIiOXTKzJIMeta } from "E:/code/website/pages/pages/faq/index.vue?macro=true";
import { default as index9jXU7bLbkjMeta } from "E:/code/website/pages/pages/faq/list/index.vue?macro=true";
import { default as faqs5JVvXlbxUjMeta } from "E:/code/website/pages/pages/faqs.vue?macro=true";
import { default as free_45engraving_45serviceuFz0dc1wC0Meta } from "E:/code/website/pages/pages/free-engraving-service.vue?macro=true";
import { default as gravityF8QJG4r1dWMeta } from "E:/code/website/pages/pages/gravity.vue?macro=true";
import { default as holidays_45gift_45guidegrjGfK2S2oMeta } from "E:/code/website/pages/pages/holidays-gift-guide.vue?macro=true";
import { default as how_45we_45give_45backrnvVmoEuQ0Meta } from "E:/code/website/pages/pages/how-we-give-back.vue?macro=true";
import { default as indexDCBje9VQYcMeta } from "E:/code/website/pages/pages/index.vue?macro=true";
import { default as indexfgq8NhCLJBMeta } from "E:/code/website/pages/pages/information-center/index.vue?macro=true";
import { default as moissaniteyxHkZbP1ZoMeta } from "E:/code/website/pages/pages/moissanite.vue?macro=true";
import { default as indexCdVH5PDOsRMeta } from "E:/code/website/pages/pages/msblue-customer-reviews/index.vue?macro=true";
import { default as our_45storysk75mv7uMHMeta } from "E:/code/website/pages/pages/our-story.vue?macro=true";
import { default as the_454cs_45of_45moissanitejFteKYIjqAMeta } from "E:/code/website/pages/pages/the-4cs-of-moissanite.vue?macro=true";
import { default as top_4520_45engagement_45wedding_45ringsojyWRPnOccMeta } from "E:/code/website/pages/pages/top-20-engagement-wedding-rings.vue?macro=true";
import { default as truly_45custom_45jewelryhzJNe93ojFMeta } from "E:/code/website/pages/pages/truly-custom-jewelry.vue?macro=true";
import { default as water_45positive_45practiceD2iNF0g1unMeta } from "E:/code/website/pages/pages/water-positive-practice.vue?macro=true";
import { default as pay_45fail_45mbqed6mz82V7Meta } from "E:/code/website/pages/pay/pay-fail-mb.vue?macro=true";
import { default as pay_failhPpDZWWXeoMeta } from "E:/code/website/pages/pay/pay_fail.vue?macro=true";
import { default as pay_successLAUUkkri7jMeta } from "E:/code/website/pages/pay/pay_success.vue?macro=true";
import { default as pay_success_mbeAXPula7RxMeta } from "E:/code/website/pages/pay/pay_success_mb.vue?macro=true";
import { default as _91id_93Rdyl9pmvctMeta } from "E:/code/website/pages/pay/result/[id].vue?macro=true";
import { default as indexAYNlLnI9b6Meta } from "E:/code/website/pages/preview/bridal-sets-customizer/index.vue?macro=true";
import { default as _91_91words_93_93CtwKKL1O5VMeta } from "E:/code/website/pages/preview/collections/[uc]/[[words]].vue?macro=true";
import { default as _91_91specs_93_93mFWrHgpmvhMeta } from "E:/code/website/pages/preview/collections/[uc]/products/[uc2]/[[specs]].vue?macro=true";
import { default as _91_91specs_93_93fxicyZmo9jMeta } from "E:/code/website/pages/preview/products/[uc]/[[specs]].vue?macro=true";
import { default as _91_91specs_93_93nFstAGMaBdMeta } from "E:/code/website/pages/products/[uc]/[[specs]].vue?macro=true";
import { default as indexUpIWtT45ujMeta } from "E:/code/website/pages/searchResult/index.vue?macro=true";
import { default as setPassword0dBikmNmrKMeta } from "E:/code/website/pages/setPassword.vue?macro=true";
import { default as turn_45360Idwfphl8bKMeta } from "E:/code/website/pages/test/turn-360.vue?macro=true";
import { default as indexFMh80lkBpQMeta } from "E:/code/website/pages/user/[url]/index.vue?macro=true";
import { default as indexkDzofTkxXlMeta } from "E:/code/website/pages/wishlist/index.vue?macro=true";
export default [
  {
    name: _4040pELUbE7osMeta?.name ?? "404",
    path: _4040pELUbE7osMeta?.path ?? "/404",
    meta: _4040pELUbE7osMeta || {},
    alias: _4040pELUbE7osMeta?.alias || [],
    redirect: _4040pELUbE7osMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/404.vue").then(m => m.default || m)
  },
  {
    name: authenticationAvYtmjPYsBMeta?.name ?? "authentication",
    path: authenticationAvYtmjPYsBMeta?.path ?? "/authentication",
    meta: authenticationAvYtmjPYsBMeta || {},
    alias: authenticationAvYtmjPYsBMeta?.alias || [],
    redirect: authenticationAvYtmjPYsBMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/authentication.vue").then(m => m.default || m)
  },
  {
    name: indexgFZH48mx4RMeta?.name ?? "cart",
    path: indexgFZH48mx4RMeta?.path ?? "/cart",
    meta: indexgFZH48mx4RMeta || {},
    alias: indexgFZH48mx4RMeta?.alias || [],
    redirect: indexgFZH48mx4RMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91id_93_93ZYfEmCKDrvMeta?.name ?? "checkout-id",
    path: _91_91id_93_93ZYfEmCKDrvMeta?.path ?? "/checkout/:id?",
    meta: _91_91id_93_93ZYfEmCKDrvMeta || {},
    alias: _91_91id_93_93ZYfEmCKDrvMeta?.alias || [],
    redirect: _91_91id_93_93ZYfEmCKDrvMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/checkout/[[id]].vue").then(m => m.default || m)
  },
  {
    name: _91_91words_93_93aXeWLIl2axMeta?.name ?? "collections-uc-words",
    path: _91_91words_93_93aXeWLIl2axMeta?.path ?? "/collections/:uc()/:words?",
    meta: _91_91words_93_93aXeWLIl2axMeta || {},
    alias: _91_91words_93_93aXeWLIl2axMeta?.alias || [],
    redirect: _91_91words_93_93aXeWLIl2axMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/collections/[uc]/[[words]].vue").then(m => m.default || m)
  },
  {
    name: _91_91specs_93_93ammNUqfy9xMeta?.name ?? "collections-uc-products-uc2-specs",
    path: _91_91specs_93_93ammNUqfy9xMeta?.path ?? "/collections/:uc()/products/:uc2()/:specs?",
    meta: _91_91specs_93_93ammNUqfy9xMeta || {},
    alias: _91_91specs_93_93ammNUqfy9xMeta?.alias || [],
    redirect: _91_91specs_93_93ammNUqfy9xMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/collections/[uc]/products/[uc2]/[[specs]].vue").then(m => m.default || m)
  },
  {
    name: indexcdPX4bD39IMeta?.name ?? "comment",
    path: indexcdPX4bD39IMeta?.path ?? "/comment",
    meta: indexcdPX4bD39IMeta || {},
    alias: indexcdPX4bD39IMeta?.alias || [],
    redirect: indexcdPX4bD39IMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/comment/index.vue").then(m => m.default || m)
  },
  {
    name: email_45verify94L10sbeBcMeta?.name ?? "email-verify",
    path: email_45verify94L10sbeBcMeta?.path ?? "/email-verify",
    meta: email_45verify94L10sbeBcMeta || {},
    alias: email_45verify94L10sbeBcMeta?.alias || [],
    redirect: email_45verify94L10sbeBcMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/email-verify.vue").then(m => m.default || m)
  },
  {
    name: indexLTxt6vO8ATMeta?.name ?? "engagementRing",
    path: indexLTxt6vO8ATMeta?.path ?? "/engagementRing",
    meta: indexLTxt6vO8ATMeta || {},
    alias: indexLTxt6vO8ATMeta?.alias || [],
    redirect: indexLTxt6vO8ATMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/engagementRing/index.vue").then(m => m.default || m)
  },
  {
    name: indexj4SmXmGDxDMeta?.name ?? "error",
    path: indexj4SmXmGDxDMeta?.path ?? "/error",
    meta: indexj4SmXmGDxDMeta || {},
    alias: indexj4SmXmGDxDMeta?.alias || [],
    redirect: indexj4SmXmGDxDMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/error/index.vue").then(m => m.default || m)
  },
  {
    name: _91pay_93V18eCGT3wJMeta?.name ?? "giftCard-pay",
    path: _91pay_93V18eCGT3wJMeta?.path ?? "/giftCard/:pay()",
    meta: _91pay_93V18eCGT3wJMeta || {},
    alias: _91pay_93V18eCGT3wJMeta?.alias || [],
    redirect: _91pay_93V18eCGT3wJMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/giftCard/[pay].vue").then(m => m.default || m)
  },
  {
    name: indexSVigrM5bguMeta?.name ?? "giftCard",
    path: indexSVigrM5bguMeta?.path ?? "/giftCard",
    meta: indexSVigrM5bguMeta || {},
    alias: indexSVigrM5bguMeta?.alias || [],
    redirect: indexSVigrM5bguMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/giftCard/index.vue").then(m => m.default || m)
  },
  {
    name: index2D5rHdUk5uMeta?.name ?? "goodsDetail",
    path: index2D5rHdUk5uMeta?.path ?? "/goodsDetail",
    meta: index2D5rHdUk5uMeta || {},
    alias: index2D5rHdUk5uMeta?.alias || [],
    redirect: index2D5rHdUk5uMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/goodsDetail/index.vue").then(m => m.default || m)
  },
  {
    name: indexD8ckpUX4Y4Meta?.name ?? "index",
    path: indexD8ckpUX4Y4Meta?.path ?? "/",
    meta: indexD8ckpUX4Y4Meta || {},
    alias: indexD8ckpUX4Y4Meta?.alias || [],
    redirect: indexD8ckpUX4Y4Meta?.redirect || undefined,
    component: () => import("E:/code/website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93KumrOLTUy3Meta?.name ?? "login-agent-id",
    path: _91id_93KumrOLTUy3Meta?.path ?? "/login-agent/:id()",
    meta: _91id_93KumrOLTUy3Meta || {},
    alias: _91id_93KumrOLTUy3Meta?.alias || [],
    redirect: _91id_93KumrOLTUy3Meta?.redirect || undefined,
    component: () => import("E:/code/website/pages/login-agent/[id].vue").then(m => m.default || m)
  },
  {
    name: index3S5TONSZllMeta?.name ?? "orderDetail-id",
    path: index3S5TONSZllMeta?.path ?? "/orderDetail/:id()",
    meta: index3S5TONSZllMeta || {},
    alias: index3S5TONSZllMeta?.alias || [],
    redirect: index3S5TONSZllMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/orderDetail/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index36VOvFCm9RMeta?.name ?? "orderDetail-replenish_data-id",
    path: index36VOvFCm9RMeta?.path ?? "/orderDetail/replenish_data/:id()",
    meta: index36VOvFCm9RMeta || {},
    alias: index36VOvFCm9RMeta?.alias || [],
    redirect: index36VOvFCm9RMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/orderDetail/replenish_data/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWZ1JJD0NhTMeta?.name ?? "pages-uc",
    path: indexWZ1JJD0NhTMeta?.path ?? "/pages/:uc()",
    meta: indexWZ1JJD0NhTMeta || {},
    alias: indexWZ1JJD0NhTMeta?.alias || [],
    redirect: indexWZ1JJD0NhTMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/[uc]/index.vue").then(m => m.default || m)
  },
  {
    name: about_45msblueBnnYiHkS84Meta?.name ?? "pages-about-msblue",
    path: about_45msblueBnnYiHkS84Meta?.path ?? "/pages/about-msblue",
    meta: about_45msblueBnnYiHkS84Meta || {},
    alias: about_45msblueBnnYiHkS84Meta?.alias || [],
    redirect: about_45msblueBnnYiHkS84Meta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/about-msblue.vue").then(m => m.default || m)
  },
  {
    name: breast_45cancer_45awareness_45jewelryEnsxKoJwfCMeta?.name ?? "pages-breast-cancer-awareness-jewelry",
    path: breast_45cancer_45awareness_45jewelryEnsxKoJwfCMeta?.path ?? "/pages/breast-cancer-awareness-jewelry",
    meta: breast_45cancer_45awareness_45jewelryEnsxKoJwfCMeta || {},
    alias: breast_45cancer_45awareness_45jewelryEnsxKoJwfCMeta?.alias || [],
    redirect: breast_45cancer_45awareness_45jewelryEnsxKoJwfCMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/breast-cancer-awareness-jewelry.vue").then(m => m.default || m)
  },
  {
    name: contact_45usHa51SdKaeiMeta?.name ?? "pages-contact-us",
    path: contact_45usHa51SdKaeiMeta?.path ?? "/pages/contact-us",
    meta: contact_45usHa51SdKaeiMeta || {},
    alias: contact_45usHa51SdKaeiMeta?.alias || [],
    redirect: contact_45usHa51SdKaeiMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: endless_45blue_45sapphire_45collection7GWGkvxGqAMeta?.name ?? "pages-endless-blue-sapphire-collection",
    path: endless_45blue_45sapphire_45collection7GWGkvxGqAMeta?.path ?? "/pages/endless-blue-sapphire-collection",
    meta: endless_45blue_45sapphire_45collection7GWGkvxGqAMeta || {},
    alias: endless_45blue_45sapphire_45collection7GWGkvxGqAMeta?.alias || [],
    redirect: endless_45blue_45sapphire_45collection7GWGkvxGqAMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/endless-blue-sapphire-collection.vue").then(m => m.default || m)
  },
  {
    name: _91uc_93Rns1xvgorWMeta?.name ?? "pages-faq-uc",
    path: _91uc_93Rns1xvgorWMeta?.path ?? "/pages/faq/:uc()",
    meta: _91uc_93Rns1xvgorWMeta || {},
    alias: _91uc_93Rns1xvgorWMeta?.alias || [],
    redirect: _91uc_93Rns1xvgorWMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/faq/[uc].vue").then(m => m.default || m)
  },
  {
    name: indexGIiOXTKzJIMeta?.name ?? "pages-faq",
    path: indexGIiOXTKzJIMeta?.path ?? "/pages/faq",
    meta: indexGIiOXTKzJIMeta || {},
    alias: indexGIiOXTKzJIMeta?.alias || [],
    redirect: indexGIiOXTKzJIMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: index9jXU7bLbkjMeta?.name ?? "pages-faq-list",
    path: index9jXU7bLbkjMeta?.path ?? "/pages/faq/list",
    meta: index9jXU7bLbkjMeta || {},
    alias: index9jXU7bLbkjMeta?.alias || [],
    redirect: index9jXU7bLbkjMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/faq/list/index.vue").then(m => m.default || m)
  },
  {
    name: faqs5JVvXlbxUjMeta?.name ?? "pages-faqs",
    path: faqs5JVvXlbxUjMeta?.path ?? "/pages/faqs",
    meta: faqs5JVvXlbxUjMeta || {},
    alias: faqs5JVvXlbxUjMeta?.alias || [],
    redirect: faqs5JVvXlbxUjMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/faqs.vue").then(m => m.default || m)
  },
  {
    name: free_45engraving_45serviceuFz0dc1wC0Meta?.name ?? "pages-free-engraving-service",
    path: free_45engraving_45serviceuFz0dc1wC0Meta?.path ?? "/pages/free-engraving-service",
    meta: free_45engraving_45serviceuFz0dc1wC0Meta || {},
    alias: free_45engraving_45serviceuFz0dc1wC0Meta?.alias || [],
    redirect: free_45engraving_45serviceuFz0dc1wC0Meta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/free-engraving-service.vue").then(m => m.default || m)
  },
  {
    name: gravityF8QJG4r1dWMeta?.name ?? "pages-gravity",
    path: gravityF8QJG4r1dWMeta?.path ?? "/pages/gravity",
    meta: gravityF8QJG4r1dWMeta || {},
    alias: gravityF8QJG4r1dWMeta?.alias || [],
    redirect: gravityF8QJG4r1dWMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/gravity.vue").then(m => m.default || m)
  },
  {
    name: holidays_45gift_45guidegrjGfK2S2oMeta?.name ?? "pages-holidays-gift-guide",
    path: holidays_45gift_45guidegrjGfK2S2oMeta?.path ?? "/pages/holidays-gift-guide",
    meta: holidays_45gift_45guidegrjGfK2S2oMeta || {},
    alias: holidays_45gift_45guidegrjGfK2S2oMeta?.alias || [],
    redirect: holidays_45gift_45guidegrjGfK2S2oMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/holidays-gift-guide.vue").then(m => m.default || m)
  },
  {
    name: how_45we_45give_45backrnvVmoEuQ0Meta?.name ?? "pages-how-we-give-back",
    path: how_45we_45give_45backrnvVmoEuQ0Meta?.path ?? "/pages/how-we-give-back",
    meta: how_45we_45give_45backrnvVmoEuQ0Meta || {},
    alias: how_45we_45give_45backrnvVmoEuQ0Meta?.alias || [],
    redirect: how_45we_45give_45backrnvVmoEuQ0Meta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/how-we-give-back.vue").then(m => m.default || m)
  },
  {
    name: indexDCBje9VQYcMeta?.name ?? "pages",
    path: indexDCBje9VQYcMeta?.path ?? "/pages",
    meta: indexDCBje9VQYcMeta || {},
    alias: indexDCBje9VQYcMeta?.alias || [],
    redirect: indexDCBje9VQYcMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexfgq8NhCLJBMeta?.name ?? "pages-information-center",
    path: indexfgq8NhCLJBMeta?.path ?? "/pages/information-center",
    meta: indexfgq8NhCLJBMeta || {},
    alias: indexfgq8NhCLJBMeta?.alias || [],
    redirect: indexfgq8NhCLJBMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/information-center/index.vue").then(m => m.default || m)
  },
  {
    name: moissaniteyxHkZbP1ZoMeta?.name ?? "pages-moissanite",
    path: moissaniteyxHkZbP1ZoMeta?.path ?? "/pages/moissanite",
    meta: moissaniteyxHkZbP1ZoMeta || {},
    alias: moissaniteyxHkZbP1ZoMeta?.alias || [],
    redirect: moissaniteyxHkZbP1ZoMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/moissanite.vue").then(m => m.default || m)
  },
  {
    name: indexCdVH5PDOsRMeta?.name ?? "pages-msblue-customer-reviews",
    path: indexCdVH5PDOsRMeta?.path ?? "/pages/msblue-customer-reviews",
    meta: indexCdVH5PDOsRMeta || {},
    alias: indexCdVH5PDOsRMeta?.alias || [],
    redirect: indexCdVH5PDOsRMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/msblue-customer-reviews/index.vue").then(m => m.default || m)
  },
  {
    name: our_45storysk75mv7uMHMeta?.name ?? "pages-our-story",
    path: our_45storysk75mv7uMHMeta?.path ?? "/pages/our-story",
    meta: our_45storysk75mv7uMHMeta || {},
    alias: our_45storysk75mv7uMHMeta?.alias || [],
    redirect: our_45storysk75mv7uMHMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/our-story.vue").then(m => m.default || m)
  },
  {
    name: the_454cs_45of_45moissanitejFteKYIjqAMeta?.name ?? "pages-the-4cs-of-moissanite",
    path: the_454cs_45of_45moissanitejFteKYIjqAMeta?.path ?? "/pages/the-4cs-of-moissanite",
    meta: the_454cs_45of_45moissanitejFteKYIjqAMeta || {},
    alias: the_454cs_45of_45moissanitejFteKYIjqAMeta?.alias || [],
    redirect: the_454cs_45of_45moissanitejFteKYIjqAMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/the-4cs-of-moissanite.vue").then(m => m.default || m)
  },
  {
    name: top_4520_45engagement_45wedding_45ringsojyWRPnOccMeta?.name ?? "pages-top-20-engagement-wedding-rings",
    path: top_4520_45engagement_45wedding_45ringsojyWRPnOccMeta?.path ?? "/pages/top-20-engagement-wedding-rings",
    meta: top_4520_45engagement_45wedding_45ringsojyWRPnOccMeta || {},
    alias: top_4520_45engagement_45wedding_45ringsojyWRPnOccMeta?.alias || [],
    redirect: top_4520_45engagement_45wedding_45ringsojyWRPnOccMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/top-20-engagement-wedding-rings.vue").then(m => m.default || m)
  },
  {
    name: truly_45custom_45jewelryhzJNe93ojFMeta?.name ?? "pages-truly-custom-jewelry",
    path: truly_45custom_45jewelryhzJNe93ojFMeta?.path ?? "/pages/truly-custom-jewelry",
    meta: truly_45custom_45jewelryhzJNe93ojFMeta || {},
    alias: truly_45custom_45jewelryhzJNe93ojFMeta?.alias || [],
    redirect: truly_45custom_45jewelryhzJNe93ojFMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/truly-custom-jewelry.vue").then(m => m.default || m)
  },
  {
    name: water_45positive_45practiceD2iNF0g1unMeta?.name ?? "pages-water-positive-practice",
    path: water_45positive_45practiceD2iNF0g1unMeta?.path ?? "/pages/water-positive-practice",
    meta: water_45positive_45practiceD2iNF0g1unMeta || {},
    alias: water_45positive_45practiceD2iNF0g1unMeta?.alias || [],
    redirect: water_45positive_45practiceD2iNF0g1unMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pages/water-positive-practice.vue").then(m => m.default || m)
  },
  {
    name: pay_45fail_45mbqed6mz82V7Meta?.name ?? "pay-pay-fail-mb",
    path: pay_45fail_45mbqed6mz82V7Meta?.path ?? "/pay/pay-fail-mb",
    meta: pay_45fail_45mbqed6mz82V7Meta || {},
    alias: pay_45fail_45mbqed6mz82V7Meta?.alias || [],
    redirect: pay_45fail_45mbqed6mz82V7Meta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pay/pay-fail-mb.vue").then(m => m.default || m)
  },
  {
    name: pay_failhPpDZWWXeoMeta?.name ?? "pay-pay_fail",
    path: pay_failhPpDZWWXeoMeta?.path ?? "/pay/pay_fail",
    meta: pay_failhPpDZWWXeoMeta || {},
    alias: pay_failhPpDZWWXeoMeta?.alias || [],
    redirect: pay_failhPpDZWWXeoMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pay/pay_fail.vue").then(m => m.default || m)
  },
  {
    name: pay_successLAUUkkri7jMeta?.name ?? "pay-pay_success",
    path: pay_successLAUUkkri7jMeta?.path ?? "/pay/pay_success",
    meta: pay_successLAUUkkri7jMeta || {},
    alias: pay_successLAUUkkri7jMeta?.alias || [],
    redirect: pay_successLAUUkkri7jMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pay/pay_success.vue").then(m => m.default || m)
  },
  {
    name: pay_success_mbeAXPula7RxMeta?.name ?? "pay-pay_success_mb",
    path: pay_success_mbeAXPula7RxMeta?.path ?? "/pay/pay_success_mb",
    meta: pay_success_mbeAXPula7RxMeta || {},
    alias: pay_success_mbeAXPula7RxMeta?.alias || [],
    redirect: pay_success_mbeAXPula7RxMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pay/pay_success_mb.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Rdyl9pmvctMeta?.name ?? "pay-result-id",
    path: _91id_93Rdyl9pmvctMeta?.path ?? "/pay/result/:id()",
    meta: _91id_93Rdyl9pmvctMeta || {},
    alias: _91id_93Rdyl9pmvctMeta?.alias || [],
    redirect: _91id_93Rdyl9pmvctMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/pay/result/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAYNlLnI9b6Meta?.name ?? "preview-bridal-sets-customizer",
    path: indexAYNlLnI9b6Meta?.path ?? "/preview/bridal-sets-customizer",
    meta: indexAYNlLnI9b6Meta || {},
    alias: indexAYNlLnI9b6Meta?.alias || [],
    redirect: indexAYNlLnI9b6Meta?.redirect || undefined,
    component: () => import("E:/code/website/pages/preview/bridal-sets-customizer/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91words_93_93CtwKKL1O5VMeta?.name ?? "preview-collections-uc-words",
    path: _91_91words_93_93CtwKKL1O5VMeta?.path ?? "/preview/collections/:uc()/:words?",
    meta: _91_91words_93_93CtwKKL1O5VMeta || {},
    alias: _91_91words_93_93CtwKKL1O5VMeta?.alias || [],
    redirect: _91_91words_93_93CtwKKL1O5VMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/preview/collections/[uc]/[[words]].vue").then(m => m.default || m)
  },
  {
    name: _91_91specs_93_93mFWrHgpmvhMeta?.name ?? "preview-collections-uc-products-uc2-specs",
    path: _91_91specs_93_93mFWrHgpmvhMeta?.path ?? "/preview/collections/:uc()/products/:uc2()/:specs?",
    meta: _91_91specs_93_93mFWrHgpmvhMeta || {},
    alias: _91_91specs_93_93mFWrHgpmvhMeta?.alias || [],
    redirect: _91_91specs_93_93mFWrHgpmvhMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/preview/collections/[uc]/products/[uc2]/[[specs]].vue").then(m => m.default || m)
  },
  {
    name: _91_91specs_93_93fxicyZmo9jMeta?.name ?? "preview-products-uc-specs",
    path: _91_91specs_93_93fxicyZmo9jMeta?.path ?? "/preview/products/:uc()/:specs?",
    meta: _91_91specs_93_93fxicyZmo9jMeta || {},
    alias: _91_91specs_93_93fxicyZmo9jMeta?.alias || [],
    redirect: _91_91specs_93_93fxicyZmo9jMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/preview/products/[uc]/[[specs]].vue").then(m => m.default || m)
  },
  {
    name: _91_91specs_93_93nFstAGMaBdMeta?.name ?? "products-uc-specs",
    path: _91_91specs_93_93nFstAGMaBdMeta?.path ?? "/products/:uc()/:specs?",
    meta: _91_91specs_93_93nFstAGMaBdMeta || {},
    alias: _91_91specs_93_93nFstAGMaBdMeta?.alias || [],
    redirect: _91_91specs_93_93nFstAGMaBdMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/products/[uc]/[[specs]].vue").then(m => m.default || m)
  },
  {
    name: indexUpIWtT45ujMeta?.name ?? "searchResult",
    path: indexUpIWtT45ujMeta?.path ?? "/searchResult",
    meta: indexUpIWtT45ujMeta || {},
    alias: indexUpIWtT45ujMeta?.alias || [],
    redirect: indexUpIWtT45ujMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/searchResult/index.vue").then(m => m.default || m)
  },
  {
    name: setPassword0dBikmNmrKMeta?.name ?? "setPassword",
    path: setPassword0dBikmNmrKMeta?.path ?? "/setPassword",
    meta: setPassword0dBikmNmrKMeta || {},
    alias: setPassword0dBikmNmrKMeta?.alias || [],
    redirect: setPassword0dBikmNmrKMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/setPassword.vue").then(m => m.default || m)
  },
  {
    name: turn_45360Idwfphl8bKMeta?.name ?? "test-turn-360",
    path: turn_45360Idwfphl8bKMeta?.path ?? "/test/turn-360",
    meta: turn_45360Idwfphl8bKMeta || {},
    alias: turn_45360Idwfphl8bKMeta?.alias || [],
    redirect: turn_45360Idwfphl8bKMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/test/turn-360.vue").then(m => m.default || m)
  },
  {
    name: indexFMh80lkBpQMeta?.name ?? "user-url",
    path: indexFMh80lkBpQMeta?.path ?? "/user/:url()",
    meta: indexFMh80lkBpQMeta || {},
    alias: indexFMh80lkBpQMeta?.alias || [],
    redirect: indexFMh80lkBpQMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/user/[url]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkDzofTkxXlMeta?.name ?? "wishlist",
    path: indexkDzofTkxXlMeta?.path ?? "/wishlist",
    meta: indexkDzofTkxXlMeta || {},
    alias: indexkDzofTkxXlMeta?.alias || [],
    redirect: indexkDzofTkxXlMeta?.redirect || undefined,
    component: () => import("E:/code/website/pages/wishlist/index.vue").then(m => m.default || m)
  }
]